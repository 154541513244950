/* eslint-disable react/jsx-no-target-blank */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import logo from '../../assets/images/suomi-seura_logo_oletus_taustalla_vaaka.svg';
import urls from '../../assets/urls.json';
import config from '../config';
import { getUser } from '../selectors/user';
import { getFeedbackUrl } from '../utils/languageUtils';
import { isAdmin } from '../utils/user';
import Link from './LinkWithLang';

const getCurrentYear = () => {
  const today = new Date();
  return today.getFullYear();
};

export function Footer(props) {
  const { language, user } = props;

  return (
    <footer
      className={classNames(
        'site-footer',
        `${isAdmin(user) ? 'site-footer--admin' : ''}`,
      )}
    >
      <div className="container">
        <Row>
          <Col md={3} sm={4}>
            <div className="footer-branding">
              <FormattedMessage id="footerLogoAlt">
                {(altText) => (
                  <img alt={altText} src={logo} className="footer-logo" />
                )}
              </FormattedMessage>
              <div className="footer-city-link">
                <a href={urls.city}>
                  <FormattedMessage id="footerCityLink" />
                </a>
              </div>
            </div>
          </Col>
          <Col md={3} sm={4}>
            <div className="site-footer-block">
              <div className="footer-header site-footer-item">Virta</div>
              <div className="site-footer-item">
                <Link to={{ path: '/info' }}>
                  <FormattedMessage id="infoHeaderText" />
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6} sm={4}>
            <div className="site-footer-small-print">
              <ul className="small-print-nav">
                <li>
                  <Link to={{ path: '/privacy' }}>
                    <FormattedMessage id="privacyLink" />
                  </Link>
                </li>
                {config.showAccessibilityInfo && (
                  <li>
                    <Link to={{ path: '/accessibility' }}>
                      <FormattedMessage id="accessibilityLink" />
                    </Link>
                  </li>
                )}
                <li>
                  <a
                    href={getFeedbackUrl(language)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="feedbackLinkText" />
                  </a>
                </li>
                <li>
                  {getCurrentYear()} <FormattedMessage id="copyrightText" />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  language: PropTypes.string,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(Footer);
