/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React from 'react';
import { Button, DropdownButton, MenuItem, Navbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';

import logo from '../../../assets/images/suomi-seura_logo_oletus_taustaton_vaaka.svg';
import Link from '../../components/LinkWithLang';
import { getUser } from '../../selectors/user';
import Login from '../../views/Auth/Login';
import LanguageSwitcher from './LanguageSwitcher';

class Header extends React.Component {
  getUserItems() {
    const { user, language } = this.props;
    const iconPath = '../../../assets/images/account-circle-blue.svg';

    if (user) {
      return (
        <DropdownButton
          pullRight
          key="profile"
          id="userMenu"
          className="user-menu user-menu--logged"
          title={
            <React.Fragment>
              <FormattedMessage id="hearingImage">
                {(altText) => (
                  <img
                    name="user"
                    alt={altText}
                    src={iconPath}
                    className="user-nav-icon"
                    aria-hidden="true"
                  />
                )}
              </FormattedMessage>
              <span className="user-name">{user.displayName}</span>
            </React.Fragment>
          }
        >
          <MenuItem key="logout" eventKey="logout" onClick={Login.logout}>
            <FormattedMessage id="logout" />
          </MenuItem>
        </DropdownButton>
      );
    }

    return [
      <FormattedMessage key="login" id="login">
        {(login) => (
          <Link to={{ path: '/auth/login' }}>
            <div className="center-vert login-container">
              <FormattedMessage id="hearingImage">
                {(altText) => (
                  <img
                    name="user"
                    alt={altText}
                    src={iconPath}
                    className="user-nav-icon"
                    aria-hidden="true"
                  />
                )}
              </FormattedMessage>
              <Button id="login-btn" aria-label={login} className="auth-link">
                <span className="">{login}</span>
              </Button>
            </div>
          </Link>
        )}
      </FormattedMessage>,
    ];
  }

  getNavItem(id, url, addSuffix = true) {
    const { history, language } = this.props;
    const active = history && history.location.pathname === url;
    let messageId = id;
    if (addSuffix) {
      messageId += 'HeaderText';
    }
    const navLink = (
      <a href="#">
        <FormattedMessage id={messageId} />
      </a>
    );
    if (url) {
      // Can't use custom link component here because it will break the navigation
      // so LinkContainer must contain same logic
      return (
        <li className={`nav-item ${active ? 'active' : ''}`}>
          <LinkContainer to={url + '?lang=' + language} className="nav-link">
            {navLink}
          </LinkContainer>
        </li>
      );
    }
    return <li className={`nav-item ${active && 'active'}`}>{navLink}</li>;
  }

  render() {
    const userItems = this.getUserItems();

    return (
      <div>
        <FormattedMessage key="headerUserNavLabel" id="headerUserNavLabel">
          {(headerUserNavLabel) => (
            <Navbar
              fluid
              defaultExpanded
              className="navbar-kerrokantasi"
              aria-label={headerUserNavLabel}
            >
              <Navbar.Header>
                <Navbar.Brand>
                  <Link to={{ path: '/' }}>
                    <FormattedMessage id="headerLogoAlt">
                      {(altText) => (
                        <img src={logo} className="navbar-logo" alt={altText} />
                      )}
                    </FormattedMessage>
                  </Link>
                </Navbar.Brand>
              </Navbar.Header>

              <div className="nav-user-menu navbar-right">
                <LanguageSwitcher currentLanguage={this.props.language} />
                {userItems}
              </div>
            </Navbar>
          )}
        </FormattedMessage>

        <FormattedMessage id="headerPagesNavLabel">
          {(headerPagesNavLabel) => (
            <Navbar
              default
              fluid
              collapseOnSelect
              className="navbar-primary"
              aria-label={headerPagesNavLabel}
            >
              <Navbar.Header>
                <Navbar.Brand>
                  <Link to={{ path: '/' }}>Virta</Link>
                </Navbar.Brand>
                <FormattedMessage id="mainMenu">
                  {(mainMenu) => <Navbar.Toggle aria-label={mainMenu} />}
                </FormattedMessage>
              </Navbar.Header>
              <Navbar.Collapse>
                <ul className="nav navbar-nav">
                  {this.getNavItem('info', '/info')}
                </ul>
              </Navbar.Collapse>
            </Navbar>
          )}
        </FormattedMessage>
      </div>
    );
  }
}

Header.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  history: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
};

Header.contextTypes = {
  history: PropTypes.object,
};

const mapDispatchToProps = () => ({});

export default withRouter(
  connect(
    (state) => ({
      user: getUser(state), // User dropdown requires this state
      language: state.language, // Language switch requires this state
      router: state.router, // Navigation activity requires this state
    }),
    mapDispatchToProps,
  )(Header),
);
