import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import config from '../src/config';
import LoadSpinner from './components/LoadSpinner';

const HomeContainer = lazy(() =>
  import(/* webpackChunkName: "home" */ './views/Home'),
);
const Info = lazy(() => import(/* webpackChunkName: "info" */ './views/Info'));
const PrivacyInfo = lazy(() =>
  import(/* webpackChunkName: "info" */ './views/PrivacyInfo'),
);
const AccessibilityInfo = lazy(() =>
  import(
    /* webpackChunkName: "accessibility_info" */ './views/AccessibilityInfo'
  ),
);
const HearingsContainer = lazy(() =>
  import(/* webpackChunkName: "hearings" */ './views/Hearings'),
);
const HearingContainer = lazy(() =>
  import(/* webpackChunkName: "hearing" */ './views/Hearing/HearingContainer'),
);
const NewHearingContainer = lazy(() =>
  import(
    /* webpackChunkName: "newhearing" */ './views/NewHearing/NewHearingContainer'
  ),
);
const FullscreenHearingContainer = lazy(() =>
  import(
    /* webpackChunkName: "fullscreen" */ './views/FullscreenHearing/FullscreenHearingContainer'
  ),
);
const UserHearings = lazy(() =>
  import(/* webpackChunkName: "userHearings" */ './views/UserHearings'),
);
const Login = lazy(() =>
  import(/* webpackChunkName: "fullscreen" */ './views/Auth/Login'),
);
const Registration = lazy(() =>
  import(/* webpackChunkName: "auth" */ './views/Auth/Registration'),
);
const RegistrationSuccess = lazy(() =>
  import(/* webpackChunkName: "auth" */ './views/Auth/RegistrationSuccess'),
);
const Confirmation = lazy(() =>
  import(/* webpackChunkName: "auth" */ './views/Auth/Confirmation'),
);
const ConfirmSuccess = lazy(() =>
  import(/* webpackChunkName: "auth" */ './views/Auth/ConfirmationSuccess'),
);
const PasswordReset = lazy(() =>
  import(/* webpackChunkName: "auth" */ './views/Auth/PasswordReset'),
);
const PasswordResetConfirm = lazy(() =>
  import(/* webpackChunkName: "auth" */ './views/Auth/PasswordResetConfirm'),
);
const FinalStatement = lazy(() =>
  import(
    /* webpackChunkName: "finalstatement" */ './views/Hearings/FinalStatement'
  ),
);
const FinalStatementPreview = lazy(() =>
  import(
    /* webpackChunkName: "finalstatementPreview" */ './views/Hearings/FinalStatementPreview'
  ),
);

/* Vanilla Redirect component can't handle dynamic rerouting,
 * so we need Redirector to access params for the hearingSlug
 */
const Redirector = ({ match }) => {
  return (
    <div>
      <Redirect to={{ path: '/' + match.params.hearingSlug }} />
    </div>
  );
};

Redirector.propTypes = {
  match: PropTypes.object,
};

const Routes = () => (
  <Suspense fallback={<LoadSpinner />}>
    <Switch>
      <Route
        exact
        path="/"
        component={(props) => <HomeContainer {...props} />}
      />
      <Route path="/auth/login" component={(props) => <Login {...props} />} />
      <Route
        path="/auth/registration/success"
        component={(props) => <RegistrationSuccess {...props} />}
      />
      <Route
        path="/auth/registration"
        component={(props) => <Registration {...props} />}
      />
      <Route
        path="/auth/confirm/success"
        component={(props) => <ConfirmSuccess {...props} />}
      />
      <Route
        path="/auth/confirm/:confirmationKey"
        component={(props) => <Confirmation {...props} />}
      />
      <Route
        path="/password-reset/confirm/:uid/:token"
        component={(props) => <PasswordResetConfirm {...props} />}
      />
      <Route
        path="/password-reset"
        component={(props) => <PasswordReset {...props} />}
      />
      <Route path="/info" component={(props) => <Info {...props} />} />
      <Route
        path="/user-hearings"
        component={(props) => <UserHearings {...props} />}
      />
      {config.showAccessibilityInfo && (
        <Route
          path="/accessibility"
          component={(props) => <AccessibilityInfo {...props} />}
        />
      )}
      <Route
        path="/privacy"
        component={(props) => <PrivacyInfo {...props} />}
      />
      <Route
        path="/hearings/:tab"
        component={(props) => <HearingsContainer {...props} />}
      />
      <Route
        path="/hearing/new"
        component={(props) => <NewHearingContainer {...props} />}
      />
      <Route
        path="/statement/preview/:statementId"
        component={(props) => <FinalStatementPreview {...props} />}
      />
      <Route
        path="/:hearingId/statement"
        component={(props) => {
          return <FinalStatement {...props} />;
        }}
      />
      <Route
        path="/hearing/:hearingSlug"
        component={(props) => <Redirector {...props} />}
      />
      <Route
        path="/:hearingSlug/fullscreen"
        component={(props) => <FullscreenHearingContainer {...props} />}
      />
      <Route
        path="/:hearingSlug/:sectionId"
        component={(props) => <HearingContainer {...props} />}
      />
      <Route
        path="/:hearingSlug"
        component={(props) => <HearingContainer {...props} />}
      />
    </Switch>
  </Suspense>
);

export default Routes;
