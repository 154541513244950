import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getCurrentUser } from './actions/user';
import CookieBar from './components/cookieBar/CookieBar';
import Footer from './components/Footer';
import Header from './components/Header';
import config from './config';
import messages from './i18n';
import Routes from './routes';
import { checkCookieConsent } from './utils/cookieUtils';
import { checkHeadlessParam } from './utils/urlQuery';

class App extends React.Component {
  getChildContext() {
    return {
      language: this.props.language,
    };
  }

  componentDidMount() {
    config.activeLanguage = this.props.language; // for non react-intl localizations
    checkCookieConsent();
    this.props.getCurrentUser();
  }

  render() {
    const locale = this.props.language;
    const favlinks = [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/favicon/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon/favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon/favicon-16x16.png',
      },
      { rel: 'manifest', href: '/favicon/manifest.json' },
      {
        rel: 'mask-icon',
        href: '/favicon/safari-pinned-tab.svg',
        color: '#0072c6',
      },
      {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: '/favicon/favicon.ico',
      },
    ];
    const favmeta = [
      { name: 'msapplication-config', content: '/favicon/browserconfig.xml' },
      { name: 'theme-color', content: '#ffffff' },
    ];
    const fullscreen = this.props.match.params.fullscreen === 'true';
    const headless = checkHeadlessParam(this.props.location.search);
    const fonts = `"Lexend",
      Arial, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;

    let header = null;
    if (!fullscreen && !headless) {
      header = (
        <Header
          slim={this.props.history.location.pathname !== '/'}
          history={this.props.history}
        />
      );
    }
    return (
      <IntlProvider locale={locale} messages={messages[locale] || {}}>
        <div className="main-layout">
          {config.showCookiebar && <CookieBar />}
          <a
            href="#main-container"
            onClick={() => document.getElementById('main-container').focus()}
            className="skip-to-main-content"
          >
            <FormattedMessage id="skipToMainContent" />
          </a>
          <Helmet
            titleTemplate="%s - Virta"
            link={favlinks}
            meta={favmeta}
          >
            <html lang={locale} />
          </Helmet>
          {header}
          <main
            className={
              fullscreen
                ? 'fullscreen'
                : classNames('main-content', { headless }, 'virta')
            }
            id="main-container"
            role="main"
            tabIndex="-1"
          >
            <Routes />
          </main>
          <Footer language={locale} />
          <ToastContainer
            bodyClassName={{
              padding: '7px 7px 7px 12px',
              fontFamily: fonts,
            }}
          />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => {
    dispatch(getCurrentUser());
  },
});

App.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  language: PropTypes.string,
  location: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  getCurrentUser: PropTypes.func,
};
App.childContextTypes = {
  language: PropTypes.string,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
