import { get } from 'lodash';
import { createAction } from 'redux-actions';

import config from '../config';

export function getCurrentUser() {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    const result = await fetch(`${config.apiBaseUrl}/v1/auth/user/`, {
      method: 'GET',
      headers: {
        Authorization: 'Token ' + token,
      },
    });

    const user = await result.json();
    const userWithOrganization = Object.assign(
      {},
      { displayName: get(user, 'first_name') + ' ' + get(user, 'last_name') },
      { nickname: get(user, 'nickname') },
      { answered_questions: get(user, 'answered_questions') },
      { adminOrganizations: get(user, 'admin_organizations', null) },
      { hasStrongAuth: false },
    );
    return dispatch(createAction('receiveUserData')(userWithOrganization));
  };
}
