import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Link from '../../components/LinkWithLang';
import config from '../../config';
import getMessage from '../../utils/getMessage';
import { localizedNotifyError } from '../../utils/notify';

class Login extends React.Component {
  form = { username: '', password: '' };

  static async logout() {
    try {
      const result = await fetch(`${config.apiBaseUrl}/v1/auth/logout/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (result.status >= 400) {
        localizedNotifyError('logoutFail');
      }
      localStorage.removeItem('token');
      window.open('/', '_self');
    } catch (error) {
      localizedNotifyError('logoutFail');
      throw new Error(`Couldn't log out: ${error}`);
    }
  }

  onSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = this.form;
      const result = await fetch(`${config.apiBaseUrl}/v1/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await result.json();
      if (result.status === 400) {
        if (response.non_field_errors.includes('E-mail is not verified.')) {
          localizedNotifyError('loginFailUnverified');
        } else {
          localizedNotifyError('loginFail');
        }
      } else if (result.status > 400) {
        localizedNotifyError('loginFail');
      }
      if (response.key) {
        localStorage.setItem('token', response.key);
        window.open('/', '_self');
      }
    } catch (error) {
      localizedNotifyError('loginFail');
      throw new Error(`Couldn't log in.: ${error}`);
    }
  };

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(() => {
      this.form[name] = value;
    });
  };

  render() {
    const { language } = this.props;

    return (
      <form className="auth-form" onSubmit={this.onSubmit}>
        <div className="auth-card">
          <h3 className="auth-heading">{getMessage('log in')}</h3>
          {this.error && <div className="alert alert-danger">{this.error}</div>}
          <div className="auth-form-content">
            <fieldset className="auth-fieldset">
              <div className="auth-text-input">
                {getMessage('username')} *
                <div className="auth-text-input__input-wrapper">
                  <input
                    required
                    id="username"
                    name="username"
                    className="auth-text-input__input"
                    type="text"
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="auth-text-input">
                {getMessage('password')} *
                <div className="auth-text-input__input-wrapper">
                  <input
                    required
                    id="password"
                    name="password"
                    className="auth-text-input__input"
                    type="password"
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </fieldset>
            <div className="auth-button-container">
              <button
                type="submit"
                className="auth-button auth-button--primary"
              >
                <span>{getMessage('log in')}</span>
              </button>
              <button
                type="button"
                aria-label={getMessage('register')}
                className="auth-button auth-button--secondary"
              >
                <Link to={{ path: '/auth/registration' }}>
                  <span>{getMessage('register')}</span>
                </Link>
              </button>
            </div>
            <Link
              className="forgot-password"
              to={{ path: '/password-reset' }}
            >
              <span>{getMessage('forgotPassword')}</span>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

Login.propTypes = {
  language: PropTypes.string,
};

const mapStateToProps = (state) => ({
  language: state.language,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
