import { handleActions } from 'redux-actions';
import updeep from 'updeep';

export default handleActions(
  {
    fetchProjects: (state) =>
      updeep(
        {
          isFetching: true,
        },
        state,
      ),
    receiveProjects: (
      state,
      {
        payload: {
          data: { results },
        },
      },
    ) =>
      updeep(
        {
          isFetching: false,
          data: [...results],
        },
        state,
      ),
    receiveProjectsError: (state) => updeep({ isFetching: false }, state),
  },
  {
    isFetching: false,
    data: [],
  },
);
